import React, { useState, useEffect } from 'react';
import API from '../../../utils/apiCalling';
import { config } from '../../../utils/apiUrl';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ColumnBarChart from '../../../components/Charts/ColumnBarChart';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const WarrantMarketTurnOver = (props) => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const settings = {
    autoplay: true,
    infinite: true,
    slidesToShow:1,
    slidesToScroll: 1,
    dots: false,
    arrow: false,
  };

  const api = new API()
  const date = new Date();
  const [marketTurnOverAll, setMarketTurnOverAll] = useState([])

  const [advImgVal, setAdvImgVal] = useState([])


  useEffect(() => {
    getHomePopup()
  }, [])
  const getHomePopup = async () => {
    let homePopupResult = await api.get(
      config.advImgPopup,
      true,
    );
    let items = [];
    if(homePopupResult.length > 0){
      for (let i = 0; i < homePopupResult.length; i++) {
        let advImgData = await api.get(config.advImgData, true, {adv_id:homePopupResult[i].id});
        items.push({
          imgSrc: advImgData,
          link: homePopupResult[i].link
        })
      }
      setAdvImgVal(items)
    }
  };

  useEffect(() => {
    getMarketTurnOverResult()
  }, [])

  const getMarketTurnOverResult = async () => {
    let indexUnderlying = await api.get(
      config.IndexFuturesRollover,
      true,
    );
    setMarketTurnOverAll(indexUnderlying)
  }

  const updateDate = marketTurnOverAll && marketTurnOverAll[0] && marketTurnOverAll[0].stats_date_obj
  return (
    <div id="dwturnover" className="pageobj">
      <h1 id="ric" style={{ display: "none" }} />
      <div className="breadcrumbbar">
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li>
              <Link to="/home?dwstat">Warrant education</Link>
            </li>
            <li className="active">Index futures rollover</li>
          </ol>
        </div>
      </div>
      <div className="stripe-bg" />
      <div className="container-fluid">
        {/* <div className="topimg hidden-sm hidden-xs">
          <img src="/img/bg/top-marketturnover.jpg" />
        </div> */}
        <div className="row">
          <div className="col-md-8">
            <div className="section">
              <div className="page-header">
                <h1>Index futures rollover</h1>
              </div>
              <p style={{fontSize:'13px'}}>
              The table below lists the dates when the relevant futures contracts are expected to roll over to the following contract month. Please note that these dates are indicative only and may be updated periodically.
              </p>
              <div id="totalTbl" className="tbl">
                <table className="table table-striped table-closed">
                  <thead>
                    <tr>
                      <th>Index underlying</th>
                      <th>Current futures contract month</th>
                      <th>Tracks next futures contract month from</th>
                    </tr>
                  </thead>
                  <tbody>
                    {marketTurnOverAll &&
                      marketTurnOverAll.map(
                        (turnOverAllData, turnOverAllIndex) => (
                          <tr
                            className="rowtmpl"
                            key={turnOverAllIndex}
                            idx={turnOverAllIndex}
                          >
                            <td className="stats_date_obj">
                              {turnOverAllData.underlying}
                            </td>
                            <td className="total">{turnOverAllData.month}</td>
                            <td className="dw">{ moment(turnOverAllData.date).format("DD-MM-YYYY")}</td>
                          </tr>
                        )
                      )}
                  </tbody>
                </table>
              </div>
                  <div style={{float:'right',fontSize:'13px',marginBottom: '20px'}}>
                    Last update :{" "}
                    <span>{moment().format("DD MMM YY")}</span>
                  </div>
              <p style={{fontSize:'13px',clear:'both'}}>Macquarie’s index warrants track the relevant futures contract month and not the index level. This is because we hedge our positions by buying and selling the associated futures contracts, which expire on set dates. Consequently, the index warrants must roll over to track the new contract month accordingly. For Hang Seng Index (HSI), Hang Seng TECH Index (HSTECH) and FBM KLCI warrants, the contract month is updated on a monthly basis. For Nikkei 225 Index (NIKKEI) and U.S. index warrants, the contract month is updated on a quarterly basis.</p>
              
            </div>
          </div>
          <div className="hidden-xs hidden-sm col-md-4">            
            <Slider {...settings} className="ad-section custom">
              {advImgVal &&
                advImgVal.map((data, index) => {
                  return (
                    <a target="_blank" href={data.link} key={index}>
                      <div>
                        <img src={data.imgSrc} />
                      </div>
                    </a>
                  );
                })}
            </Slider>
            <div className="section qlink-section">
              <div className="page-header">
                <h2 className="small">Live matrix</h2>
              </div>
              <p className="section-p">
                Makes trading warrant easy by showing investors where our bid
                prices will be throughout the day
              </p>
              <div className="section-qlink-img section-qlink-lmatrix">
                <Link to="/tools/livematrix/" className="section-qlink-achor">
                  <img src="/img/bullet/qlink-achor.png" />
                </Link>
              </div>
            </div>
            <div className="section qlink-section">
              <div className="page-header">
                <h2 className="small">Warrant search</h2>
              </div>
              <p>
                Find the right warrant according to your investment criteria.
              </p>
              <div className="section-qlink-img section-qlink-dwsearch">
                <Link to="/tools/warrantsearch" className="section-qlink-achor">
                  <img src="/img/bullet/qlink-achor.png" />
                </Link>
              </div>
            </div>
            <div className="section qlink-section">
              <div className="page-header">
                <h2 className="small">Warrant calculator</h2>
              </div>
              <p>
                Estimates your future warrant price given a change in underlying
                price, time or implied volatility
              </p>
              <div className="section-qlink-img section-qlink-dwcalculator">
                <Link
                  to="/tools/warrantcalculator"
                  className="section-qlink-achor"
                >
                  <img src="/img/bullet/qlink-achor.png" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default WarrantMarketTurnOver;
